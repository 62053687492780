.ingredient-unit-select-wrapper {
  width: 8.25rem;
  /* background-color: var(--white);
  border-radius: 4px; */
}

@container (max-width: 480px) {
  .ingredient-unit-select-wrapper {
    width: 5rem;
  }
}
