.settings-list-item {
  margin-bottom: .6rem;
  border: 1px solid var(--french-gray);
  /* background-color: var(--seasalt); */
  border-radius: 5px;
  padding: .6rem;
}

.settings-list-item h4 {
  margin-bottom: .25rem;
}
