#import-page {
  height: 100%;
  width: 100%;
  position: relative;
}

#import-page .form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20vh;
}

#import-page img.mascot {
  height: 200px;
  margin-right: 40px;
  margin-bottom: 1rem;
}
