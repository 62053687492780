.library-item {
  display: flex;
  width: 100%;
  border: 1px solid var(--french-gray);
  padding: 10px 15px;
  border-radius: 10px;
  justify-content: space-between;
  margin-bottom: 10px;
}

.library-item:hover {
  transition: none;
  background-color: var(--seasalt);
  cursor: pointer;
  border-color: var(--french-gray-2);
}

.library-item .row {
  color: var(--slate-gray);
}

.library-item span {
  padding-right: 8px;
  margin-right: 8px;
  border-right: 1px solid var(--french-gray);
  display: inline-block;
}

.library-item span:last-child {
  border-right: 0;
  margin-right: 0;
  padding-right: 0;
}

.library-item .buttons {
  display: flex;
  align-items: center;
}

.library-item .buttons button {
  height: 44px;
  width: 44px;
  color: var(--onyx);
  background-color: transparent;
  border: none;
}

.library-item .buttons button:hover {
  cursor: pointer;
  color: var(--slate-gray);
}

@container library (max-width: 480px){
  .library-item {
    flex-direction: column;
  }

  .library-item .right {
    margin-top: 10px;
  }

  .library-item .buttons button:first-child {
    margin: 0;
  }
}
