/* Default styles (mobile-first) */
#home-page {
  padding: 0 .75rem;
}

.view {
  padding-top: 20vh;
  text-align: center;
}

.view:first-of-type {
  min-height: 100vh;
}

.view:nth-child(1) .the-recipe-reaper {
  width: 54vw;
  margin-right: 8vw;
}

.view:nth-child(2) .the-recipe-reaper {
  width: 51vw;
  margin-left: 8vw;
}

.view:nth-child(3) .the-recipe-reaper {
  width: 62vw;
  margin-left: 3vw;
}

.view h2 {
  margin-top: .3rem;
  font-weight: 900;
  font-size: 2.2rem;
  margin-bottom: .8rem;
}

.view section > p {
  color: var(--french-gray-2);
  margin-bottom: 1.2rem;
  font-size: 1.1rem;
}

.view br {
  display: none;
}

.view ul {
  width: min(80vw, 400px);
  color: var(--slate-gray);
  text-align: left;
  font-size: 1rem;
  margin: 0 auto
}

.view li {
  display: flex;
  align-items: center;
  margin-bottom: .7rem;
}

.view .icon {
  font-size: .8rem;
  margin-right: .6rem;
}

#home-page .form-wrapper {
  height: 100vh;
  display: flex;
}

#home-page .form-wrapper h2 {
  font-size: 1.8rem;
  font-weight: 800;
}

#footer-signup-form {
  width: 100%;
  margin-top: auto;
}

.recipe-popup-modal {
  overflow-y: scroll;
  display: flex;
  padding: 1.5rem 0;
}

.recipe-popup-modal .modal-box {
  margin: auto;
  width: min(100%, 60rem);
}

/* Tablets */
@media screen and (min-width: 480px) {
  .view:nth-child(1) .the-recipe-reaper {
    width: 33vw;
    margin-right: 8vw;
  }
  
  .view:nth-child(2) .the-recipe-reaper {
    width: 31vw;
    margin-left: 5vw;
  }
  
  .view:nth-child(3) .the-recipe-reaper {
    width: 39vw;
    margin-left: 3vw;
  }

  .view h2 {
    font-size: 2.5rem;
  }

  .view br {
    display: inline;
  }
}

/* Large tablets */
@media screen and (min-width: 768px) {
  #home-page {
    padding: 0 1rem;
  }

  .view li {
    margin-bottom: 1.2rem;
  }
}

/* Laptops */
@media screen and (min-width: 1024px) {
  .view {
    display: flex;
    text-align: left;
    align-items: center;
    justify-content: center;
  }

  .view:first-child {
    padding-top: 0;
  }

  .view:first-child .the-recipe-reaper {
    width: 340px;
    margin-right: min(4rem, 3vw);
  }

  .view:nth-child(2) .the-recipe-reaper {
    order: 2;
    width: 310px;
    /* margin-left: 3rem; */
    margin-left: min(2vw, 3rem);
  }

  .view:nth-child(3) .the-recipe-reaper {
    width: 380px;
    margin-right: 2rem;
  }

  .view ul {
    margin-left: 1rem;
  }
}

/* Desktops */
@media screen and (min-width: 1280px) {
  .view {
    min-height: 100vh;
    padding-top: 0;
  }

  .view h2 {
    font-size: 3.4rem;
  }
}
