#recipe-item-signup-modal {
  display: flex;
}

#recipe-item-signup-modal .account-form {
  width: 100%;
}

#recipe-item-signup-modal .signup-form-wrapper {
  margin: auto;
  background-color: var(--white);
  width: 440px;
  display: flex;
  justify-content: center;
  padding: 1rem;
  border-radius: .7rem;
  /* padding: max(1.5vw, 3vh); */
  height: fit-content;
}

#recipe-item-signup-modal #signup-form {
  margin-top: 0;
  padding-bottom: 0;
  width: 100%;
}

@media screen and (min-width: 480px) {
  #recipe-item-signup-modal .signup-form-wrapper {
    padding: 1.5rem;
    border-radius: 1rem;
  }
}

@media screen and (max-width: 480px) {
  #recipe-item-signup-modal .signup-form-wrapper {
    width: 90vw;
  }
}
