#recipe-scraping-form {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  --recipe-scraping-form-height: 3.2rem;
}

#recipe-scraping-form .MuiTextField-root {
  max-width: 480px;
}

#recipe-scraping-form .MuiInputBase-root {
  border-radius: 8px;
}

#recipe-scraping-form input {
  font-size: 1.1rem;
  padding: .8rem;
  color: var(--onyx);
}

#recipe-scraping-form input::placeholder {
  opacity: 1;
  font-weight: 300;
  color: var(--french-gray-2);
}

#recipe-url-helper-text {
  text-align: center;
  margin-right: 0;
}

#recipe-scraping-form button {
  font-size: 1.1rem;
  font-weight: 500;
  padding: .93rem 1.1rem;
  margin-top: .5rem;
  border-radius: 8px;
  white-space: nowrap;
}

/* Inline variant */

@media screen and (min-width: 1024px) {
  #recipe-scraping-form.inline {
    flex-direction: row;
    align-items: flex-start;
  }

  #recipe-scraping-form.inline #recipe-url-helper-text {
    padding-left: .8rem;
    text-align: left;
    position: absolute;
    top: var(--recipe-scraping-form-height);
  }

  #recipe-scraping-form.inline button  {
    margin-top: 0;
    margin-left: .5rem;
  }
}
