#navbar {
  position: fixed;
  left: 0;
  padding: .7rem 1rem;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--white);
  z-index: 1;
  transition: border-color .1s ease;
}

#navbar.border {
  box-shadow: 0 2px 3px -2px rgba(0,0,0,.25);
}

#app-logo {
  font-size: 1.9rem;
  font-family: 'Oswald', sans-serif;
  color: var(--french-gray-2);
  font-weight: 600;
}

#app-logo strong {
  font-weight: 600;
  color: var(--eerie-black);
}

#navbar-links a {
  margin-left: 10px;
  font-weight: 500;
  text-align: center;
  display: inline-block;
  width: 100px;
  padding: 14px 0;
  border-radius: 40px;
}

#navbar-links a.active {
  font-weight: 600;
  background-color: var(--antiflash-white);
}

#navbar-links a:not(.active):hover {
  background-color: var(--seasalt);
}

#toggle-nav {
  min-height: 44px;
  min-width: 44px;
}

#toggle-nav .icon {
  min-height: 28px;
}

/* Phones and small tablest */
@media screen and (max-width: 600px) {
  #navbar {
    box-shadow: 0 2px 3px -2px rgba(0,0,0,.25);
  }

  #navbar-links {
    display: flex;
    position: absolute;
    background-color: var(--white);
    right: 0px;
    top: 96%;
    flex-direction: column;
    padding: 0 10px;
    box-shadow: -2px 2px 3px -2px rgba(0,0,0,.25);
    border-radius: 0 0 0 .3rem;
  }

  #navbar-links.hidden {
    display: none;
  }

  #navbar-links a {
    margin: 5px 0;
  }

  button#toggle-nav {
    background-color: white;
    border: none;
    color: var(--onyx);
  }
}

/* Medium tablets */
@media screen and (min-width: 601px) {
  button#toggle-nav {
    display: none;
  }
}

/* Large tablets and up */
@media screen and (min-width: 1024px) {
  #navbar {
    padding-left: 10vw;
    padding-right: 10vw;
  }
}
