.confirmation-display {
  width: 36vw;
  min-width: 300px;
  line-height: 1.8rem;
}

.confirmation-display h3 {
  margin-bottom: .5rem;
}

.confirmation-display .buttons-container {
  display: flex;
  margin-top: 1rem;
  width: 100%;
  justify-content: flex-end;
}

.confirmation-display .buttons-container * {
  margin-left: 8px;
}
