.account-form {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  margin: auto;
  font-family: 'Work Sans', sans-serif;
}

.account-form h2 {
  text-align: center;
}

.account-form .signup-msg {
  margin-top: 20px;
  color: var(--slate-gray);
  margin-bottom: 16px;
}

.account-form .signup-msg a {
  color: var(--eerie-black);
  text-decoration: underline;
}

.account-form label {
  margin-top: 16px;
  margin-bottom: 8px;
}

.account-form button {
  margin-top: 16px;
  padding: 14px;
  font-weight: 500;
}

.account-form-submit-error {
  margin-top: 16px;
}
