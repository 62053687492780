#signup-page {
  display: flex;
}

.account-page {
  display: flex;
  min-height: 100vh;
}

.signup-msg {
  text-align: center;
}

.account-page form {
  padding: .5rem;
  width: 100%;
}