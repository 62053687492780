.ingredient-quantity-input-wrapper {
  /* background-color: var(--white);
  border-radius: 4px; */
  max-width: 6rem;
}

@media screen and (min-width: 481px){
  .ingredient-quantity-input-wrapper {
    min-width: 6rem;
  }
}
