#sidebar {
  width: var(--sidebar-width);
  padding: 0px .5rem;
  background-color: var(--seasalt);
  border-right: 1px solid var(--french-gray);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  z-index: 1;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
}

#sidebar-logo span {
  color: var(--french-gray-2);
}

#sidebar.collapsed {
  padding: 0 .5rem;
}

#sidebar h1 {
  margin: 10px 10px 0 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.7rem;
  font-family: 'Oswald', sans-serif;
  color: var(--eerie-black);
  height: 60px;
}

#sidebar-collapse-btn {
  color: var(--onyx);
}

#sidebar-collapse-btn:hover {
  cursor: pointer;
}

#sidebar.collapsed h1 {
  justify-content: center;
  margin-left: 0;
  margin-right: 0;
}

#sidebar .btn {
  display: flex;
  align-items: center; 
  width: 100%;
  font-size: 16px;
  color: var(--slate-gray);
  font-weight: 500;
  padding: 14px;
  margin-top: 5px;
  white-space: nowrap;
}

@media screen and (min-width: 481px) {
  #sidebar .btn:hover {
    background-color: var(--antiflash-white);
    border-radius: 12px;
  }

  #sidebar .active {
    background-color: var(--antiflash-white);
    border-radius: 12px;
    color: var(--onyx);
    font-weight: 600;
  }  
}

#sidebar .active {
  color: var(--onyx);
  font-weight: 600;
}

.sidebar-nav-btn-text {
  margin-left: 10px;
}

#sidebar.collapsed .sidebar-nav-btn-text {
  display: none;
}

.sidebar-icon-wrapper {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-icon {
  color: var(--slate-gray);
}

.active .sidebar-icon {
  color: var(--onyx);
}

#sidebar .lower {
  padding: 20px 5px;
}

#sidebar.collapsed #theme-toggle {
  display: none;
}

#sidebar .logout-container {
  color: var(--french-gray-2);
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 400;
}

#sidebar .logout-container * {
  color: var(--french-gray-2);
}

#sidebar.collapsed .logout-container {
  display: flex;
  justify-content: center;
}

#sidebar.collapsed .logout-container span {
  display: none;
}

@media screen and (min-width: 481px) {
  #sidebar {
    transition: width var(--sidebar-transition-duration) ease;
  }
}

/* On mobile, the .collapsed class's style is no different from the default  */
@media screen and (max-width: 480px) {

  #sidebar.collapsed, #sidebar {
    width: 100vw;
    top: auto;
    right: 0;
    left: 0;
    padding: .5rem 5vw;
    bottom: 0;
    border-top: 1px solid var(--french-gray);
    border-right: none;
    /* border-radius: 0 0 4px 0; */
  }

  #sidebar .btn {
    margin: 0;
    display: inline-block;
    text-align: center;
    padding: 0;
    /* display: flex;
    flex-direction: column; */
  }

  .sidebar-icon-wrapper {
    width: 100%;
    align-items: center;
  }

  .sidebar-nav-btn-text, #sidebar.collapsed .sidebar-nav-btn-text {
    margin: 0;
    display: block;
    font-size: .7rem;
  }

  #sidebar h1 {
    display: none;
  }

  #sidebar ul {
    display: flex;
    justify-content: space-between;
  }

  #sidebar .lower {
    display: none;
  }
}
